import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { patternDotsDef } from "@nivo/core";
import useWindowSize from "../../hooks/useWindowSize";

import { strings } from "../../strings/budget";
import { nFormatter, truncate } from "../../utils/formatting";
import { budgetColors } from "../../strings/colors";

const ExpenditureBars = ({ data, keyVisibilities, lang }) => {
	const { width: windowWidth } = useWindowSize();
	const keys = data.keys.filter((key) => keyVisibilities[key]);

	const theme = {
		axis: {
			ticks: {
				text: {
					fontSize: windowWidth > 719 ? 14 : 12,
				},
			},
		},
		markers: {
			text: {
				fontSize: 14,
			},
		},
		legends: {
			text: {
				fontSize: 12,
			},
		},
		tooltip: {
			container: {
				padding: 0,
			},
		},
		labels: {
			text: {
				fontSize: windowWidth > 719 ? 15 : 13,
			},
		},
	};

	const getBarColor = (bar) => {
		return budgetColors[bar.id]["background"];
	};

	const getTextColor = (bar) => {
		return budgetColors[bar.data.id]["text"];
	};

	return (
		<ResponsiveBar
			data={data}
			margin={{
				top: 20,
				right: 20,
				bottom: 80,
				left: windowWidth > 719 ? 250 : 150,
			}}
			defs={[
				patternDotsDef("pink_dots", {
					size: 5,
					padding: 2,
					stagger: true,
					background: "inherit",
					color: "#C50084",
				}),
				patternDotsDef("yellow_dots", {
					size: 5,
					padding: 2,
					stagger: true,
					background: "inherit",
					color: "#F9C867",
				}),
			]}
			fill={[
				{ match: { id: "loans_additions" }, id: "yellow_dots" },
				{ match: { id: "expenditure_additions" }, id: "pink_dots" },
			]}
			layout="horizontal"
			indexBy="id"
			animate={true}
			colors={getBarColor}
			labelTextColor={getTextColor}
			keys={keys}
			theme={theme}
			axisBottom={{
				tickSize: 5,
				tickValues: windowWidth > 540 ? (windowWidth > 719 ? 4 : 3) : 2,
				tickPadding: 5,
				tickRotation: 0,
				legendPosition: "middle",
				legendOffset: 32,
				format: (value) => `${nFormatter(value, 1, true)}`,
			}}
			axisLeft={{
				tickSize: 0,
				tickPadding: 15,
				tickRotation: 0,
				format: (value) =>
					`${truncate(value, windowWidth > 719 ? 35 : 20)}`,
			}}
			label={({ value }) => {
				return `${nFormatter(value, 1)}`;
			}}
			labelSkipWidth={75}
			labelSkipHeight={20}
			tooltipFormat={(value) => {
				const formattedValue = new Intl.NumberFormat().format(value);
				return `${formattedValue}`;
			}}
			markers={[
				{
					axis: "x",
					value: 0,
					lineStyle: { stroke: "rgba(0, 0, 0, 1)", strokeWidth: 1 },
				},
			]}
			tooltip={(row) => {
				return (
					<div
						style={{
							background: "white",
							borderTop: `3px solid ${row.color}`,
							maxWidth: "250px",
						}}
					>
						<div
							style={{
								textAlign: "center",
								padding: "9px 12px",
								background: "#f0f0f0",
							}}
						>
							<strong
								style={{
									display: "block",
									textTransform: "uppercase",
									fontSize: "12px",
									marginBottom: "5px",
								}}
							>
								{strings[lang][row.id]}
							</strong>
							{row.indexValue}
						</div>
						<div
							style={{
								padding: "9px 12px",
								textAlign: "center",
							}}
						>
							<p style={{ padding: 0, margin: 0, fontSize: "24px" }}>
								{new Intl.NumberFormat().format(row.value)} €
							</p>
							{row.data.type && (
								<p style={{ padding: 0, margin: 0, fontSize: "14px" }}>
									({row.data.type})
								</p>
							)}
							{row.data.sector && (
								<p style={{ padding: 0, margin: 0, fontSize: "14px" }}>
									({row.data.sector})
								</p>
							)}
						</div>
						{row.data.comment && (
							<div
								style={{
									padding: "9px 12px",
									textAlign: "center",
									borderTop: "1px solid #f0f0f0",
								}}
							>
								<p style={{ padding: 0, margin: 0, fontSize: "14px" }}>
									{row.data.comment}
								</p>
							</div>
						)}
					</div>
				);
			}}
		/>
	);
};

export default ExpenditureBars;

import React from "react";
import LanguageContext from "./components/LanguageContext";
import { LiveAnnouncer } from "react-aria-live";
import Employment from "./components/Employment";
import PublicExpenditure from "./components/PublicExpenditure";
import Budget from "./components/Budget";

function App({ language }) {
	const lang = language ? language : "fi";

	return (
		<LanguageContext.Provider value={lang}>
			<LiveAnnouncer>
				<Budget />
				<div style={{ height: "200px" }}></div>
				<Employment />
				<div style={{ height: "200px" }}></div>
				<PublicExpenditure />
			</LiveAnnouncer>
		</LanguageContext.Provider>
	);
}

export default App;

export const defaultVisibilities = {
	no: true,
	uk: false,
	se: true,
	fi: true,
	sk: false,
	si: false,
	ro: false,
	pt: false,
	pl: false,
	at: false,
	nl: false,
	mt: false,
	hu: false,
	lu: false,
	lt: false,
	lv: false,
	cy: false,
	it: false,
	hr: false,
	fr: false,
	es: false,
	el: false,
	ie: false,
	ee: false,
	de: false,
	dk: true,
	cz: false,
	bg: false,
	be: false,
	euro: true,
	eu27: true,
	ref_sce: true,
	risk_sce: true,
	high_lif_exp_sce: true,
	lower_fertility: true,
	high_tfp: true,
	high_employ: true,
	low_employ: true,
	old_workers_high: true,
	high_migr: true,
	low_migr: true,
	healthy_ageing: true,
};

import React from "react";
import styled from "styled-components";

const Table = ({ data, type }) => {
	const header = type === "scenario" ? "Maat" : "Skenaariot";

	return (
		<StyledTable aria-labelledby="table-heading">
			<Thead>
				<Tr>
					<Th scope="col">{header}</Th>
					{data[0].data.map((rowData, rowDataIndex) => {
						return (
							<Th key={`header-${rowDataIndex}`} scope="col">
								{rowData.x}
							</Th>
						);
					})}
				</Tr>
			</Thead>
			<Tbody>
				{data.map((row, rowIndex) => {
					if (row.visible) {
						return (
							<Tr key={`row-${row.id}`}>
								<ThBody scope="row">
									{row.label.name ? row.label.name : row.label}
								</ThBody>
								{row.data.map((rowData, rowDataIndex) => {
									let value = parseFloat(rowData.y).toFixed(1);
									value = value.toString().replace(".", ",");

									return (
										<Td key={`cell-${rowIndex}-${rowDataIndex}`}>
											{value}
										</Td>
									);
								})}
							</Tr>
						);
					} else {
						return null;
					}
				})}
			</Tbody>
		</StyledTable>
	);
};

const StyledTable = styled.table`
	width: 100%;
	text-align: left;
	position: absolute;
	left: 0;
	top: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
`;
const Thead = styled.thead``;
const Tr = styled.tr``;
const Th = styled.th`
	padding: 5px;
	background-color: #ccc;
`;
const ThBody = styled.th`
	padding: 5px;
	background-color: #f0f0f0;
`;
const Tbody = styled.tbody``;
const Td = styled.td`
	padding: 5px;
`;

export default Table;

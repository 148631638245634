import React, { useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../LanguageContext";

import { strings } from "../../strings/employment";

const Heading = ({ selectedQuarter, selectedGender }) => {
	const lang = useContext(LanguageContext);
	const yearHeading =
		selectedGender === "both"
			? `(${selectedQuarter})`
			: `(${selectedQuarter}, `;
	const genderHeading =
		selectedGender === "both"
			? ""
			: `${strings[lang][selectedGender].toLowerCase()})`;

	return (
		<StyledHeading id="texttable-heading">{`${strings[lang]["heading"]} ${yearHeading} ${genderHeading}`}</StyledHeading>
	);
};

const StyledHeading = styled.h3`
	margin-top: 2rem;
`;

export default Heading;

import React, { useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import LanguageContext from "../LanguageContext";
// import { LiveMessage } from "react-aria-live";

import { budgetColors } from "../../strings/colors";
import { strings } from "../../strings/budget";

const Legends = ({ keys, keyVisibilities, setKeyVisibilities }) => {
	const lang = useContext(LanguageContext);
	// const [message, setMessage] = useState("");

	useEffect(() => {
		let visibilities = {};
		keys.forEach((key) => {
			visibilities = { ...visibilities, [key]: true };
		});
		setKeyVisibilities(visibilities);
	}, [keys, setKeyVisibilities]);

	const toggleVisibility = (key) => {
		const newVisibilities = {
			...keyVisibilities,
			[key]: !keyVisibilities[key],
		};
		setKeyVisibilities(newVisibilities);
	};

	return (
		<>
			{/* <LiveMessage message={message} aria-live="polite" /> */}
			<Fieldset>
				<HtmlLegend>{strings[lang]["legends"]}</HtmlLegend>
				<LegendsWrapper>
					{keys.map((key) => {
						const label = strings[lang][key];
						const isVisible = keyVisibilities[key];
						return (
							<Legend key={key}>
								<LegendInput
									id={key}
									type="checkbox"
									checked={isVisible || true}
									onChange={() => toggleVisibility(key)}
								/>
								<LegendLabel
									htmlFor={key}
									color={
										isVisible
											? budgetColors[key].background
											: "#f0f0f0"
									}
									isVisible={isVisible}
									hasYellowDots={key === "loans_additions"}
									hasPinkDots={key === "expenditure_additions"}
								>
									{label}
								</LegendLabel>
							</Legend>
						);
					})}
				</LegendsWrapper>
			</Fieldset>
		</>
	);
};

const Fieldset = styled.fieldset`
	border: 1px solid #ccc;
	padding: 1.5rem;
`;

const HtmlLegend = styled.legend``;

const LegendsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@media (min-width: 719px) {
		justify-content: center;
	}
`;

const Legend = styled.div``;

const LegendLabel = styled.label`
	position: relative;
	display: flex;
	justify-content: center;
	background: transparent;
	border: 0;
	border-radius: 5px;
	padding: 4px 8px;
	font-size: 14px;
	margin: 5px;
	color: ${(props) => (props.isVisible ? "black" : "#999")};

	&:hover {
		cursor: pointer;
		background: #f4f4f4;
	}

	@media (min-width: 719px) {
		font-size: 16px;
	}

	&:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 1rem;
		display: inline-block;
		margin-right: 0.5rem;
		background-color: ${(props) => props.color};
	}

	${(props) =>
		!props.isVisible &&
		css`
			&:after {
				position: absolute;
				left: 1rem;
				top: 0;
				content: "";
				width: 1px;
				height: 100%;
				background: red;
				transform: rotate(45deg);
			}
		`}

	${(props) =>
		props.hasYellowDots &&
		props.isVisible &&
		css`
			&:before {
				background: linear-gradient(90deg, #ffe3ad 2px, transparent 1%)
						center,
					linear-gradient(#ffe3ad 2px, transparent 1%) center, #cc9a35;
				background-size: 5px 5px;
			}
		`}
	
	${(props) =>
		props.hasPinkDots &&
		props.isVisible &&
		css`
			&:before {
				background: linear-gradient(90deg, #a70070 2px, transparent 1%)
						center,
					linear-gradient(#a70070 2px, transparent 1%) center, #f9b1e1;
				background-size: 5px 5px;
			}
		`}
`;

const LegendInput = styled.input`
	position: absolute;
	opacity: 0;

	&:focus + ${LegendLabel}:before {
		box-shadow: 0 0 6px hsl(208, 99%, 50%);
		border-color: hsl(208, 99%, 50%);
		outline: none;
	}
`;

export default Legends;

import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import LanguageContext from "../LanguageContext";
import { LiveMessage } from "react-aria-live";

import { colors } from "../../strings/colors";
import { strings } from "../../strings/publicExpenditure";

const Legends = ({ data, setData }) => {
	const lang = useContext(LanguageContext);
	const [message, setMessage] = useState("");

	const toggleVisibility = (id) => {
		const storedVisibilities =
			JSON.parse(sessionStorage.getItem("line-visibilities")) || {};

		let newData = data.slice();
		const elementIndex = newData.findIndex((line) => line.id === id);
		newData[elementIndex].visible = !newData[elementIndex].visible;
		newData[elementIndex].color = newData[elementIndex].visible
			? colors[elementIndex]
			: "#f0f0f0";
		setData(newData);

		const newId = newData[elementIndex].id;
		storedVisibilities[newId] = newData[elementIndex].visible;
		sessionStorage.setItem(
			"line-visibilities",
			JSON.stringify(storedVisibilities)
		);

		const msgString = newData[elementIndex].visible
			? `${strings[lang]["change_message_added"]}`
			: `${strings[lang]["change_message_removed"]}`;
		setMessage(
			`${strings[lang]["change_message"]} (${msgString} ${
				newData[elementIndex].label.short
					? newData[elementIndex].label.short
					: newData[elementIndex].label
			})`
		);
	};

	return (
		<>
			<LiveMessage message={message} aria-live="polite" />
			<Fieldset>
				<HtmlLegend>{strings[lang]["legends"]}</HtmlLegend>
				<LegendsWrapper>
					{data.map((point) => {
						const label = point.label?.name
							? point.label?.name
							: point.label;
						return (
							<Legend key={point.id}>
								<LegendInput
									id={point.id}
									type="checkbox"
									checked={point.visible}
									onChange={() => toggleVisibility(point.id)}
								/>
								<LegendLabel
									htmlFor={point.id}
									color={point.color}
									isVisible={point.visible}
								>
									{label}
								</LegendLabel>
							</Legend>
						);
					})}
				</LegendsWrapper>
			</Fieldset>
		</>
	);
};

const Fieldset = styled.fieldset`
	border: 1px solid #ccc;
	padding: 1.5rem;
`;

const HtmlLegend = styled.legend``;

const LegendsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@media (min-width: 719px) {
		justify-content: center;
	}
`;

const Legend = styled.div``;

const LegendLabel = styled.label`
	position: relative;
	display: flex;
	justify-content: center;
	background: transparent;
	border: 0;
	border-radius: 5px;
	padding: 4px 8px;
	font-size: 14px;
	margin: 5px;
	
	color: ${(props) => (props.isVisible ? "black" : "#999")};
	/* text-decoration: ${(props) =>
		props.isVisible ? "none" : "line-through"}; */

	&:hover {
		cursor: pointer;
		background: #f4f4f4;
	}

	@media (min-width: 719px) {
		font-size: 16px;
	}

	&:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 1rem;
		display: inline-block;
		margin-right: 0.5rem;
		background-color: ${(props) => props.color};
	}

	${(props) =>
		!props.isVisible &&
		css`
			&:after {
				position: absolute;
				left: 1rem;
				top: 0;
				content: "";
				width: 1px;
				height: 100%;
				background: red;
				transform: rotate(45deg);
			}
		`}
`;

const LegendInput = styled.input`
	position: absolute;
	opacity: 0;

	&:focus + ${LegendLabel}:before {
		box-shadow: 0 0 6px hsl(208, 99%, 50%);
		border-color: hsl(208, 99%, 50%);
		outline: none;
	}
`;

export default Legends;

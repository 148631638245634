import React, { useContext } from "react";
import LanguageContext from "../LanguageContext";
import styled from "styled-components";

import { ResponsiveSankey } from "@nivo/sankey";

import useWindowSize from "../../hooks/useWindowSize";

import { strings } from "../../strings/employment";

const Sankey = ({ data }) => {
	// const restrictedData = {
	// 	links: data.links.filter((obj) => {
	// 		const target = obj.target.split("_")[1];
	// 		return target !== obj.source;
	// 	}),
	// 	nodes: data.nodes,
	// };
	const size = useWindowSize();
	const lang = useContext(LanguageContext);

	const themeConfig = {
		fontSize: size.width > 719 ? "14px" : "12px",
	};

	return (
		<ResponsiveSankey
			theme={themeConfig}
			data={data}
			//layout={size.width > 719 ? "vertical" : "horizontal"}
			layout="horizontal"
			colors={(data) => data.color}
			label={(d) => `${strings[lang][d.id]}`}
			enableLinkGradient={true}
			nodeOpacity={1}
			nodeThickness={25}
			nodeInnerPadding={4}
			nodeSpacing={10}
			nodeBorderWidth={0}
			nodeBorderColor={{ from: "color" }}
			linkContract={size.width > 719 ? 1 : 0}
			linkOpacity={0.9}
			linkHoverOpacity={0.8}
			labelPosition="outside"
			//labelOrientation={size.width > 719 ? "horizontal" : "vertical"}
			labelOrientation="vertical"
			labelPadding={15}
			sort="input"
			margin={{
				top: 60,
				right: 25,
				bottom: 25,
				left: 20,
			}}
			linkTooltip={(node) => {
				const string = `${node.source.id}_${node.target.id}`;
				const value = new Intl.NumberFormat().format(node.value * 1000);
				return (
					<Tooltip>
						<Border
							startColor={node.startColor}
							endColor={node.endColor}
						></Border>
						<Value>{value}</Value>
						<br /> <String>{strings[lang][string]}</String>
					</Tooltip>
				);
			}}
		/>
	);
};

const Tooltip = styled.div`
	max-width: 130px;
	white-space: normal;
	text-align: center;
	padding: 0;
	background: "white";
	border: "1px solid #ccc";
	padding-bottom: 0.5rem;
`;

const Value = styled.strong`
	display: inline-block;
	font-size: 24px;
	padding-top: 0.75rem;
	margin-bottom: 0;
	padding-bottom: 0;
`;

const String = styled.p`
	padding: 0 0.5rem;
	margin: 0;
`;

const Border = styled.div`
	width: 150px;
	margin-left: -10px;
	height: 5px;
	margin-top: -5px;
	background: ${(props) => {
		return `linear-gradient(to right, ${props.startColor}, ${props.endColor})`;
	}};
`;

export default Sankey;

import React from "react";
import { ResponsiveLine } from "@nivo/line";

const Line = ({ data, pointStrings, type }) => {
	const visibleData = data.filter((line) => line.visible === true);

	return (
		<ResponsiveLine
			data={visibleData}
			// animate={false}
			margin={{ top: 30, right: 30, bottom: 60, left: 60 }}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: false,
				reverse: false,
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				orient: "bottom",
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "Ajanjakso",
				legendOffset: 36,
				legendPosition: "middle",
			}}
			axisLeft={{
				orient: "left",
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "Julkisten menojen määrä",
				legendOffset: -40,
				legendPosition: "middle",
			}}
			colors={(data) => data.color}
			curve="natural"
			pointSize={10}
			pointColor={{ from: "color", modifiers: [] }}
			pointBorderWidth={2}
			pointBorderColor={{ theme: "background" }}
			pointLabel="y"
			pointLabelYOffset={-12}
			useMesh={true}
			tooltip={({ point }) => {
				const legend = pointStrings[point.serieId];

				const string = type === "country" ? legend?.short : legend;

				return (
					<div
						style={{
							background: "white",
							padding: "9px 12px",
							border: "1px solid #ccc",
							borderTop: `3px solid ${point.serieColor}`,
						}}
					>
						<div
							style={{
								padding: "3px 0",
								textAlign: "center",
							}}
						>
							<p style={{ padding: 0, margin: 0 }}>
								<strong>{string}</strong>
							</p>
							<p style={{ padding: 0, margin: 0, fontSize: "24px" }}>
								{point.data.yFormatted}
							</p>
						</div>
					</div>
				);
			}}
		/>
	);
};

export default Line;

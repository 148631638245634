export const nFormatter = (num, digits, hasCurrencySign = false) => {
	const currencySign = hasCurrencySign ? " €" : "";
	const si = [
		{ value: 1e9, symbol: " mrd." },
		{ value: 1e6, symbol: " milj." },
		{ value: 1e3, symbol: "k" },
	];

	for (let i = 0; i < si.length; i++) {
		if (num > 0 && num >= si[i].value) {
			return (
				(num / si[i].value)
					.toFixed(digits)
					.replace(/\./g, ",")
					.replace(/,0+$|(\.[0-9]*[1-9])0+$/, "$1") +
				si[i].symbol +
				currencySign
			);
		}
		if (num < 0 && num <= si[i].value) {
			return (
				(num / si[i].value)
					.toFixed(digits)
					.replace(/\./g, ",")
					.replace(/,0+$|(\.[0-9]*[1-9])0+$/, "$1") +
				si[i].symbol +
				currencySign
			);
		}
	}

	return num.toString() + currencySign;
};

export const truncate = (str, n) => {
	return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

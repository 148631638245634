import React, { useState, useEffect } from "react";
import { dsv } from "d3-fetch";
import styled from "styled-components";

import { nodesLinks } from "./Employment/nodes-links";

import Sankey from "./Employment/Sankey";
import QuarterSelector from "./Employment/QuarterSelector";
import GenderSelector from "./Employment/GenderSelector";
import TextTable from "./Employment/TextTable";
import Heading from "./Employment/Heading";

const Employment = () => {
	const [data, setData] = useState(null);
	const [quarters, setQuarters] = useState(null);
	const [selectedGender, setSelectedGender] = useState("both");
	const [selectedQuarter, setSelectedQuarter] = useState("2019/Q3");

	useEffect(() => {
		dsv(";", "/data/employment/employment.csv").then((csv) => {
			const parsedData = [];
			csv.forEach((d, i) => {
				if (!parsedData.includes(d.quarter)) {
					parsedData.push(d.quarter);
				}
			});
			setQuarters(parsedData);
		});
	}, []);

	useEffect(() => {
		dsv(";", "/data/employment/employment.csv").then((csv) => {
			const parsedData = JSON.parse(JSON.stringify(nodesLinks));

			csv.forEach((d, i) => {
				if (
					selectedQuarter === d.quarter &&
					(selectedGender === d.gender || selectedGender === "both")
				) {
					const keys = Object.keys(d);
					const values = Object.values(d);

					keys.forEach((x, i) => {
						let sourceName;
						let targetName;
						if (x !== "quarter" && x !== "gender") {
							let split = x.split("_");
							sourceName = `${split[0]}`;
							targetName = `${split[1]}_${split[2]}`;
						}
						const sourceIndex = keys.findIndex((obj) => obj === x);
						const targetIndex = parsedData["links"].findIndex(
							(obj) =>
								obj.source === sourceName && obj.target === targetName
						);
						if (targetIndex > -1) {
							parsedData["links"][targetIndex].value =
								parsedData["links"][targetIndex].value +
								parseInt(values[sourceIndex]);
						}
					});
				}
			});
			setData(parsedData);
		});
	}, [selectedQuarter, selectedGender]);

	return (
		<>
			<ControlsWrapper>
				<QuarterSelector
					quarters={quarters}
					selectedQuarter={selectedQuarter}
					setSelectedQuarter={setSelectedQuarter}
				/>
				<GenderSelector setSelectedGender={setSelectedGender} />
			</ControlsWrapper>
			{data && (
				<>
					<Heading
						selectedQuarter={selectedQuarter}
						selectedGender={selectedGender}
					/>
					<ChartWrapper aria-hidden="true">
						<Sankey data={data} />
					</ChartWrapper>
					<TextTable data={data.links} />
				</>
			)}
		</>
	);
};

const ControlsWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media (min-width: 600px) {
		align-items: center;
		flex-direction: row;
	}
`;

const ChartWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 700px;

	@media (min-width: 719px) {
		height: 800px;
	}
`;

export default Employment;

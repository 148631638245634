export const countries = {
	fi: {
		no: "Norja",
		uk: "Iso-Britannia",
		se: "Ruotsi",
		fi: "Suomi",
		sk: "Slovakia",
		si: "Slovenia",
		ro: "Romania",
		pt: "Portugali",
		pl: "Puola",
		at: "Itävalta",
		nl: "Hollanti",
		mt: "Malta",
		hu: "Unkari",
		lu: "Luxemburg",
		lt: "Liettua",
		lv: "Latvia",
		cy: "Kypros",
		it: "Italia",
		hr: "Kroatia",
		fr: "Ranska",
		es: "Espanja",
		el: "Kreikka",
		ie: "Irlanti",
		ee: "Estonia",
		de: "Saksa",
		dk: "Tanska",
		cz: "Tsekki",
		bg: "Bulgaria",
		be: "Belgia",
		euro: "Euroalue",
		eu27: "EU (27)",
	},
	en: {
		no: "Norja",
		uk: "Iso-Britannia",
		se: "Ruotsi",
		fi: "Suomi",
		sk: "Slovakia",
		si: "Slovenia",
		ro: "Romania",
		pt: "Portugali",
		pl: "Puola",
		at: "Itävalta",
		nl: "Hollanti",
		mt: "Malta",
		hu: "Unkari",
		lu: "Luxemburg",
		lt: "Liettua",
		lv: "Latvia",
		cy: "Kypros",
		it: "Italia",
		hr: "Kroatia",
		fr: "Ranska",
		es: "Espanja",
		el: "Kreikka",
		ie: "Irlanti",
		ee: "Estonia",
		de: "Saksa",
		dk: "Tanska",
		cz: "Tsekki",
		bg: "Bulgaria",
		be: "Belgia",
		euro: "Euroalue",
		eu27: "EU (27)",
	},
	sv: {
		no: "Norja",
		uk: "Iso-Britannia",
		se: "Ruotsi",
		fi: "Suomi",
		sk: "Slovakia",
		si: "Slovenia",
		ro: "Romania",
		pt: "Portugali",
		pl: "Puola",
		at: "Itävalta",
		nl: "Hollanti",
		mt: "Malta",
		hu: "Unkari",
		lu: "Luxemburg",
		lt: "Liettua",
		lv: "Latvia",
		cy: "Kypros",
		it: "Italia",
		hr: "Kroatia",
		fr: "Ranska",
		es: "Espanja",
		el: "Kreikka",
		ie: "Irlanti",
		ee: "Estonia",
		de: "Saksa",
		dk: "Tanska",
		cz: "Tsekki",
		bg: "Bulgaria",
		be: "Belgia",
		euro: "Euroalue",
		eu27: "EU (27)",
	},
};

import React, { useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../LanguageContext";

import { strings } from "../../strings/employment";

const TextTable = ({ data }) => {
	const lang = useContext(LanguageContext);
	const rowData = data.map((row) => {
		const id = `${row.source}_${row.target}`;
		return {
			id,
			startColor: row.startColor,
			endColor: row.endColor,
			value: row.value,
		};
	});

	return (
		<>
			<StyledTable aria-labelledby="texttable-heading">
				<Thead>
					<Tr>
						<Th></Th>
						<Th>{strings[lang]["units"]}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{rowData.map((row, rowIndex) => {
						const value = new Intl.NumberFormat().format(
							row.value * 1000
						);
						return (
							<Tr key={rowIndex} isMain={row.isMain}>
								<ThBody scope="row">
									<ColorIcon
										startColor={row.startColor}
										endColor={row.endColor}
									/>
									{strings[lang][row.id]}
								</ThBody>
								<Td>{value}</Td>
							</Tr>
						);
					})}
				</Tbody>
			</StyledTable>
		</>
	);
};

const StyledTable = styled.table`
	width: 100%;
	text-align: left;
	margin-top: 20px;
`;
const Thead = styled.thead``;
const Th = styled.th`
	text-align: left;
	padding: 5px;
	background-color: #edf4f6;
	border-bottom: 1px solid #0075b0;
`;
const Tbody = styled.tbody``;
const ThBody = styled.th`
	text-align: left;
	padding: 5px;
`;
const Td = styled.td`
	text-align: left;
	padding: 5px;
`;
const ColorIcon = styled.span`
	width: 2em;
	height: 0.75em;
	margin-right: 0.75em;
	display: inline-block;
	background: ${(props) => {
		return `linear-gradient(to right, ${props.startColor}, ${props.endColor})`;
	}};
	border-radius: 3px;
`;
const Tr = styled.tr`
	&:not(:last-child) ${Td}, &:not(:last-child) ${ThBody} {
		border-bottom: 1px solid #edf4f6;
	}
`;

export default TextTable;

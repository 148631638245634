import React, { useState, useEffect, useContext } from "react";
import LanguageContext from "./LanguageContext";
import { LiveMessage } from "react-aria-live";
import { dsv } from "d3-fetch";
import styled from "styled-components";
import "../styles/tabs.css";

import { countries } from "../strings/countries";
import { scenarios } from "../strings/scenarios";
import { colors } from "../strings/colors";
import { strings } from "../strings/publicExpenditure";
import { defaultVisibilities } from "../bools/defaultVisibilities";

import Line from "./PublicExpenditure/Line";
import Legends from "./PublicExpenditure/Legends";
import Table from "./PublicExpenditure/Table";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const PublicExpenditure = () => {
	const lang = useContext(LanguageContext);
	const [message, setMessage] = useState("");
	const [mode, setMode] = useState(0);
	const [scenario, setScenario] = useState("ref_sce");
	const [country, setCountry] = useState("fi");
	const [data, setData] = useState(null);

	const scenarioKeys = Object.keys(scenarios[lang]);
	const scenarioLabels = Object.values(scenarios[lang]);
	const scenarioOptions = scenarioKeys.map((scenario, index) => {
		return { value: scenario, label: scenarioLabels[index].name };
	});

	const countryKeys = Object.keys(countries[lang]);
	const countryLabels = Object.values(countries[lang]);
	const countryOptions = countryKeys.map((country, index) => {
		return { value: country, label: countryLabels[index] };
	});

	const handleTabsChange = (index) => {
		setMode(index);
	};

	const handleScenarioChange = (event) => {
		setScenario(event.value);
		setMessage(
			`${strings[lang]["change_message"]} (${
				scenarios[lang][event.value].short
			})`
		);
	};

	const handleCountryChange = (event) => {
		setCountry(event.value);
		setMessage(
			`${strings[lang]["change_message"]} (${countries[lang][event.value]})`
		);
	};

	useEffect(() => {
		const storedVisibilities = JSON.parse(
			sessionStorage.getItem("line-visibilities")
		);
		if (!storedVisibilities) {
			sessionStorage.setItem(
				"line-visibilities",
				JSON.stringify(defaultVisibilities)
			);
		}
	}, []);

	useEffect(() => {
		const url = mode
			? `/data/countries/${country}.csv`
			: `/data/scenarios/${scenario}.csv`;

		dsv(";", url).then((csv) => {
			const parsedData = [];
			const storedVisibilities =
				JSON.parse(sessionStorage.getItem("line-visibilities")) || {};

			csv.forEach((d, i) => {
				parsedData[i] = {};
				parsedData[i].id = d.id;
				if (mode === 0) {
					parsedData[i].label = countries[lang][d.id];
				} else {
					parsedData[i].label = scenarios[lang][d.id];
				}
				parsedData[i].visible =
					storedVisibilities[parsedData[i].id] ?? true;
				parsedData[i].color = parsedData[i].visible ? colors[i] : "#f0f0f0";
				delete d.id;

				const xs = Object.keys(d);
				const ys = Object.values(d);

				const dataArray = [];
				xs.forEach((x, i) => {
					const data = {
						x: parseInt(x),
						y: parseFloat(ys[i].replace(/,/g, ".")).toFixed(2),
					};
					dataArray.push(data);
				});

				parsedData[i].data = dataArray;
			});

			setData(parsedData);
		});
	}, [scenario, country, mode, lang]);

	return (
		<>
			<LiveMessage message={message} aria-live="polite" />
			<Tabs onSelect={handleTabsChange}>
				<TabList>
					<Tab>{strings[lang]["scenario_view"]}</Tab>
					<Tab>{strings[lang]["country_view"]}</Tab>
				</TabList>

				<TabPanel>
					<PanelHeader>
						<Heading id="table-heading">
							{scenarios[lang][scenario]["name"]}
						</Heading>
						<Label htmlFor="scenario-select">
							{strings[lang]["choose_scenario"]}:
						</Label>
						<Select
							options={scenarioOptions}
							defaultValue={scenarioOptions.find(
								(obj) => obj.value === scenario
							)}
							inputId="scenario-select"
							placeholder={`${strings[lang]["choose_scenario"]}...`}
							noOptionsMessage={() => strings[lang]["no_hits"]}
							onChange={(event) => handleScenarioChange(event)}
							styles={selectStyles}
						/>
					</PanelHeader>
					{data && (
						<>
							<ChartWrapper aria-hidden="true">
								<Line
									data={data}
									pointStrings={countries[lang]}
									type="scenario"
								/>
							</ChartWrapper>
							<Table data={data} type="scenario" />
							<Legends data={data} setData={setData} />
						</>
					)}
				</TabPanel>
				<TabPanel>
					<PanelHeader>
						<Heading id="table-heading">
							{countries[lang][country]}
						</Heading>
						<Label htmlFor="country-select">
							{strings[lang]["choose_country"]}:
						</Label>
						<Select
							options={countryOptions}
							defaultValue={countryOptions.find(
								(obj) => obj.value === country
							)}
							inputId="country-select"
							placeholder={`${strings[lang]["choose_country"]}...`}
							noOptionsMessage={() => strings[lang]["no_hits"]}
							onChange={(event) => handleCountryChange(event)}
							styles={selectStyles}
						/>
					</PanelHeader>
					{data && (
						<>
							<ChartWrapper aria-hidden="true">
								<Line
									data={data}
									pointStrings={scenarios[lang]}
									type="country"
								/>
							</ChartWrapper>
							<Table data={data} type="country" />
							<Legends data={data} setData={setData} />
						</>
					)}
				</TabPanel>
			</Tabs>
		</>
	);
};

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: "100%",
		maxWidth: "400px",
	}),
};

const Label = styled.label`
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0.5rem;

	@media (min-width: 719px) {
		margin-left: auto;
		margin-right: 0.75rem;
		margin-bottom: 0;
	}
`;

const PanelHeader = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;

	@media (min-width: 719px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`;

const ChartWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 600px;

	@media (min-width: 719px) {
		height: 800px;
	}
`;

const Heading = styled.h3`
	padding: 0 30px 0 0;
`;

export default PublicExpenditure;

export const strings = {
	fi: {
		scenario_view: "Tarkastele skenaarioittain",
		country_view: "Tarkastele maittain",
		choose_scenario: "Valitse skenaario",
		choose_country: "Valitse maa",
		no_hits: "Ei osumia",
		legends: "Selitteet",
		change_message: "Taulukko päivittyi",
		change_message_added: "Lisätty",
		change_message_removed: "Poistettu",
	},
	en: {
		scenario_view: "View by scenario",
		country_view: "View by country",
		choose_scenario: "Choose scenario",
		choose_country: "Choose country",
		no_hits: "No hits",
		legends: "Legends",
		change_message: "Table updated",
		change_message_added: "Added",
		change_message_removed: "Removed",
	},
	sv: {
		scenario_view: "Tarkastele skenaarioittain",
		country_view: "Tarkastele maittain",
		choose_scenario: "Valitse skenaario",
		choose_country: "Valitse maa",
		no_hits: "Ei osumia",
		legends: "Selitteet",
		change_message: "Taulukko päivittyi",
		change_message_added: "Lisätty",
		change_message_removed: "Poistettu",
	},
};

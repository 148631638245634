export const strings = {
	fi: {
		heading: "Siirtymät työmarkkinoilla edellisestä vuosineljänneksestä",
		quarter: "Vuosineljännes",
		select_quarter: "Valitse vuosineljännes...",
		gender: "Sukupuoli",
		both: "Kaikki",
		male: "Miehet",
		female: "Naiset",
		units: "henkilöä",
		employed: "Työlliset",
		unemployed: "Työttömät",
		outside: "Työvoiman ulkopuoliset",
		to_employed: "Työlliset",
		to_unemployed: "Työttömät",
		to_outside: "Työvoiman ulkopuoliset",
		to_new: "Työpaikkaa vaihtaneet",
		employed_to_employed: "Työllisenä pysyneet",
		unemployed_to_unemployed: "Työttömänä pysyneet",
		outside_to_outside: "Työvoiman ulkopuolella pysyneet",
		employed_to_unemployed: "Työllisestä työttömäksi siirtyneet",
		employed_to_outside: "Työllisestä työvoiman ulkopuolelle siirtyneet",
		unemployed_to_employed: "Työttömästä työlliseksi siirtyneet",
		unemployed_to_outside: "Työttömästä työvoiman ulkopuolelle siirtyneet",
		outside_to_employed: "Työvoiman ulkopuolelta työlliseksi siirtyneet",
		outside_to_unemployed: "Työvoiman ulkopuolelta työttömäksi siirtyneet",
		employed_to_new: "Työpaikkaa vaihtaneet työlliset",
		change_message: "Taulukko päivittyi",
	},
	en: {
		heading: "EN Siirtymät työmarkkinoilla edellisestä vuosineljänneksestä",
		quarter: "Quarter",
		select_quarter: "Choose quarter...",
		gender: "Gender",
		both: "All",
		male: "Men",
		female: "Women",
		units: "persons",
		employed: "Employed",
		unemployed: "Unemployed",
		outside: "Outside workforce",
		to_employed: "Employed",
		to_unemployed: "Unemployed",
		to_outside: "Outside workforce",
		to_new: "New job",
		employed_to_employed: "Still employed",
		unemployed_to_unemployed: "Still unemployed",
		outside_to_outside: "Still outside workforce",
		employed_to_unemployed: "Employed to unemployed",
		employed_to_outside: "Employed to outside workforce",
		unemployed_to_employed: "Unemployed to employed",
		unemployed_to_outside: "Unemployed to outside workforce",
		outside_to_employed: "Outside workforce to employed",
		outside_to_unemployed: "Outside workforce to unemployed",
		employed_to_new: "Employed to new job",
		change_message: "Table updated",
	},
	sv: {
		heading: "SV Siirtymät työmarkkinoilla edellisestä vuosineljänneksestä",
		quarter: "Vuosineljännes",
		select_quarter: "Valitse vuosineljännes...",
		gender: "Sukupuoli",
		both: "Kaikki",
		male: "Miehet",
		female: "Naiset",
		units: "henkilöä",
		employed: "Työlliset",
		unemployed: "Työttömät",
		outside: "Työvoiman ulkopuoliset",
		to_employed: "Työlliset",
		to_unemployed: "Työttömät",
		to_outside: "Työvoiman ulkopuoliset",
		to_new: "Työpaikkaa vaihtaneet",
		employed_to_employed: "Työllisenä pysyneet",
		unemployed_to_unemployed: "Työttömänä pysyneet",
		outside_to_outside: "Työvoiman ulkopuolella pysyneet",
		employed_to_unemployed: "Työllisestä työttömäksi siirtyneet",
		employed_to_outside: "Työllisestä työvoiman ulkopuolelle siirtyneet",
		unemployed_to_employed: "Työttömästä työlliseksi siirtyneet",
		unemployed_to_outside: "Työttömästä työvoiman ulkopuolelle siirtyneet",
		outside_to_employed: "Työvoiman ulkopuolelta työlliseksi siirtyneet",
		outside_to_unemployed: "Työvoiman ulkopuolelta työttömäksi siirtyneet",
		employed_to_new: "Työpaikkaa vaihtaneet työlliset",
		change_message: "Taulukko päivittyi",
	},
};

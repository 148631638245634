import React, { useState, useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../LanguageContext";
import { LiveMessage } from "react-aria-live";

import { strings } from "../../strings/employment";

import Select from "react-select";

const QuarterSelector = ({ quarters, setSelectedQuarter }) => {
	const lang = useContext(LanguageContext);
	const [message, setMessage] = useState("");

	const handleQuarterChange = (event) => {
		setSelectedQuarter(event.value);
		setMessage(`${strings[lang]["change_message"]} (${event.value})`);
	};

	const quarterOptions = quarters?.map((quarter) => {
		return { value: quarter, label: quarter };
	});

	return (
		<>
			<LiveMessage message={message} aria-live="polite" />
			<Label htmlFor="quarter-select">{strings[lang]["quarter"]}:</Label>
			{quarterOptions && (
				<Select
					options={quarterOptions}
					defaultValue={quarterOptions[quarterOptions.length - 1]}
					inputId="quarter-select"
					placeholder={strings[lang]["select_quarter"]}
					onChange={(event) => handleQuarterChange(event)}
					styles={selectStyles}
					isSearchable={false}
				/>
			)}
		</>
	);
};

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: "100%",
		maxWidth: "250px",
	}),
};

const Label = styled.label`
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0.5rem;

	@media (min-width: 600px) {
		margin-left: auto;
		margin-right: 0.75rem;
		margin-bottom: 0;
	}
`;

export default QuarterSelector;

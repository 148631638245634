export const strings = {
	fi: {
		all_view: "Yleisnäkymä",
		income_view: "Tuloarviot",
		expenditure_view: "Menoarviot",
		income: "Tulot",
		loans: "Lainat",
		loans_additions: "Lainalisäykset",
		income_total: "Tulot yhteensä",
		income_deductions: "Tulovähennykset",
		expenditure: "Menot",
		expenditure_additions: "Menolisäykset",
		original: "Talousarvio 2020",
		additional_budgets: "Lisätalousarviot",
		original_and_additional: "Talousarvio ja lisätalousarviot yhteensä",
		budget: "Talousarvio 2020",
		additional_budget_1: "Lisätalousarvio 1",
		additional_budget_2: "Lisätalousarvio 2",
		additional_budget_3: "Lisätalousarvio 3",
		additional_budget_4: "Lisätalousarvio 4",
		sector_select: "Valitse hallinnonala",
		all: "Kaikki",
		no_hits: "Ei osumia",
		legends: "Selitteet",
		change_message: "Taulukko päivittyi",
		change_message_added: "Lisätty",
		change_message_removed: "Poistettu",
	},
	en: {
		all_view: "Yleisnäkymä",
		income_view: "Tuloarviot",
		expenditure_view: "Menoarviot",
		income: "Tulot",
		loans: "Lainat",
		loans_additions: "Lainalisäykset",
		income_total: "Tulot yhteensä",
		income_deductions: "Tulovähennykset",
		expenditure: "Menot",
		expenditure_additions: "Menolisäykset",
		original: "Talousarvio 2020",
		additional_budgets: "Lisätalousarviot",
		original_and_additional: "Talousarvio ja lisätalousarviot yhteensä",
		budget: "Talousarvio 2020",
		additional_budget_1: "Lisätalousarvio 1",
		additional_budget_2: "Lisätalousarvio 2",
		additional_budget_3: "Lisätalousarvio 3",
		additional_budget_4: "Lisätalousarvio 4",
		sector_select: "Valitse hallinnonala",
		all: "Kaikki",
		no_hits: "No hits",
		legends: "Legends",
		change_message: "Table updated",
		change_message_added: "Added",
		change_message_removed: "Removed",
	},
	sv: {
		all_view: "Yleisnäkymä",
		income_view: "Tuloarviot",
		expenditure_view: "Menoarviot",
		income: "Tulot",
		loans: "Lainat",
		loans_additions: "Lainalisäykset",
		income_total: "Tulot yhteensä",
		income_deductions: "Tulovähennykset",
		expenditure: "Menot",
		expenditure_additions: "Menolisäykset",
		original: "Talousarvio 2020",
		additional_budgets: "Lisätalousarviot",
		original_and_additional: "Talousarvio ja lisätalousarviot yhteensä",
		budget: "Talousarvio 2020",
		additional_budget_1: "Lisätalousarvio 1",
		additional_budget_2: "Lisätalousarvio 2",
		additional_budget_3: "Lisätalousarvio 3",
		additional_budget_4: "Lisätalousarvio 4",
		sector_select: "Valitse hallinnonala",
		all: "Kaikki",
		no_hits: "Ei osumia",
		legends: "Selitteet",
		change_message: "Taulukko päivittyi",
		change_message_added: "Lisätty",
		change_message_removed: "Poistettu",
	},
};

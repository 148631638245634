import React, { useState, useEffect, useContext } from "react";
import LanguageContext from "./LanguageContext";
import { dsv } from "d3-fetch";
import styled from "styled-components";
import "../styles/tabs.css";

import { strings } from "../strings/budget";

import ExpenditureBars from "./Budget/ExpenditureBars";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SectorSelector from "./Budget/SectorSelector";
import Legends from "./Budget/Legends";

const Budget = () => {
	const lang = useContext(LanguageContext);
	const [data, setData] = useState(null);
	const [mode, setMode] = useState(0);
	const [sectorNames, setSectorNames] = useState(null);
	const [selectedSector, setSelectedSector] = useState("all");
	const [keyVisibilities, setKeyVisibilities] = useState({});

	const handleTabsChange = (index) => {
		setMode(index);
	};

	useEffect(() => {
		const host =
			process.env.REACT_APP_STAGE === "dev"
				? ""
				: "https://vtv-visualisoinnit.s3.eu-north-1.amazonaws.com";
		const url = `${host}/data/budget/expenditure.csv`;

		dsv(";", url).then((csv) => {
			const sectorNames = [];

			csv.forEach((d) => {
				if (!sectorNames.includes(d.sector)) {
					sectorNames.push(d.sector);
				}
			});

			setSectorNames(sectorNames);
		});
	}, []);

	useEffect(() => {
		let filename;
		switch (mode) {
			case 0:
				filename = "total";
				break;
			case 1:
				filename = "expenditure";
				break;
			case 2:
				filename = "income";
				break;
			default:
				break;
		}
		const host =
			process.env.REACT_APP_STAGE === "dev"
				? ""
				: "https://vtv-visualisoinnit.s3.eu-north-1.amazonaws.com";
		const url = `${host}/data/budget/${filename}.csv`;

		dsv(";", url).then((csv) => {
			const parsedData = [];

			if (mode === 0) {
				csv.forEach((d, i) => {
					parsedData[i] = {};
					parsedData[i].id = strings[lang][d.id];
					delete d.id;

					const xs = Object.keys(d);
					const ys = Object.values(d);

					parsedData.keys = xs;

					xs.forEach((x, j) => {
						parsedData[i][x] = Number(ys[j]);
					});
				});
				parsedData.reverse();
			} else if (mode === 1 && selectedSector === "all") {
				csv.forEach((d) => {
					if (!parsedData.some((row) => row.id === d.sector)) {
						parsedData.push({ id: d.sector });
					}

					const sectorIndex = parsedData.findIndex((row) => {
						return row.id === d.sector;
					});

					delete d.sector;
					delete d.id;
					delete d.name;
					delete d.type;
					delete d.comment;

					const xs = Object.keys(d);
					const ys = Object.values(d);

					parsedData.keys = xs;

					xs.forEach((x, j) => {
						const newNumber =
							parsedData[sectorIndex][x] !== undefined
								? parsedData[sectorIndex][x] + Number(ys[j])
								: Number(ys[j]);
						parsedData[sectorIndex][x] = newNumber;
					});
				});
			} else if (mode === 1 && selectedSector !== "all") {
				csv.forEach((d) => {
					if (d.sector === selectedSector) {
						parsedData.push({ id: d.name });

						const nameIndex = parsedData.findIndex((row) => {
							return row.id === d.name;
						});

						delete d.sector;
						delete d.id;
						delete d.name;

						const xs = Object.keys(d);
						const ys = Object.values(d);

						const keys = [];
						xs.forEach((x, j) => {
							if (x === "type" || x === "comment") {
								parsedData[nameIndex][x] = ys[j];
							} else {
								keys.push(x);
								const newNumber =
									parsedData[nameIndex][x] !== undefined
										? parsedData[nameIndex][x] + Number(ys[j])
										: Number(ys[j]);
								parsedData[nameIndex][x] = newNumber;
							}
						});
						parsedData.keys = keys;
					}
				});
			} else if (mode === 2) {
				csv.forEach((d) => {
					parsedData.push({ id: d.name });

					const nameIndex = parsedData.findIndex((row) => {
						return row.id === d.name;
					});

					delete d.id;
					delete d.name;

					const xs = Object.keys(d);
					const ys = Object.values(d);

					const keys = [];
					xs.forEach((x, j) => {
						if (x === "type" || x === "comment" || x === "sector") {
							parsedData[nameIndex][x] = ys[j];
						} else {
							keys.push(x);
							const newNumber =
								parsedData[nameIndex][x] !== undefined
									? parsedData[nameIndex][x] + Number(ys[j])
									: Number(ys[j]);
							parsedData[nameIndex][x] = newNumber;
						}
					});
					parsedData.keys = keys;
				});
				parsedData.reverse();
			}
			setData(parsedData);
		});
	}, [lang, mode, selectedSector]);

	const ChartAndLegends = data && (
		<>
			<ChartWrapper aria-hidden="true" barCount={data.length}>
				<ExpenditureBars
					data={data}
					lang={lang}
					keyVisibilities={keyVisibilities}
				/>
			</ChartWrapper>
			<Legends
				keys={data.keys}
				keyVisibilities={keyVisibilities}
				setKeyVisibilities={setKeyVisibilities}
			/>
		</>
	);

	return (
		<>
			<Tabs onSelect={handleTabsChange}>
				<TabList>
					<Tab>{strings[lang]["all_view"]}</Tab>
					<Tab>{strings[lang]["expenditure_view"]}</Tab>
					<Tab>{strings[lang]["income_view"]}</Tab>
				</TabList>
				<TabPanel>{ChartAndLegends}</TabPanel>
				<TabPanel>
					{sectorNames && (
						<ControlsWrapper>
							<SectorSelector
								selectedSector={selectedSector}
								setSelectedSector={setSelectedSector}
								sectorNames={sectorNames}
							/>
						</ControlsWrapper>
					)}
					{ChartAndLegends}
				</TabPanel>
				<TabPanel>{ChartAndLegends}</TabPanel>
			</Tabs>
		</>
	);
};

const ChartWrapper = styled.div`
	display: flex;
	width: 100%;
	height: ${(props) => `calc((${props.barCount} * 40px) + 100px)`};

	@media (min-width: 719px) {
		height: ${(props) => `calc((${props.barCount} * 50px) + 100px)`};
	}
`;

const ControlsWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media (min-width: 600px) {
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;
		margin-top: 1rem;
	}
`;

export default Budget;

export const nodesLinks = {
	nodes: [
		{
			id: "employed",
			color: "#0075b0",
			textColor: "white",
		},
		{
			id: "to_new",
			color: "#0075b0",
			textColor: "white",
		},
		{
			id: "unemployed",
			color: "#FDC66E",
			textColor: "black",
		},
		{
			id: "outside",
			color: "#FF6875",
			textColor: "#6A272C",
		},
		{
			id: "to_employed",
			color: "#0075b0",
			textColor: "white",
		},
		{
			id: "to_unemployed",
			color: "#FDC66E",
			textColor: "black",
		},
		{
			id: "to_outside",
			color: "#FF6875",
			textColor: "#6A272C",
		},
	],
	links: [
		{
			source: "employed",
			target: "to_new",
			value: 0,
			color: "#0075b0",
			startColor: "#0075b0",
			endColor: "#0075b0",
		},
		{
			source: "employed",
			target: "to_employed",
			value: 0,
			color: "#0075b0",
			startColor: "#0075b0",
			endColor: "#0075b0",
		},
		{
			source: "employed",
			target: "to_unemployed",
			value: 0,
			color: "#0075b0",
			startColor: "#0075b0",
			endColor: "#FDC66E",
		},
		{
			source: "employed",
			target: "to_outside",
			value: 0,
			color: "#0075b0",
			startColor: "#0075b0",
			endColor: "#FF6875",
		},

		{
			source: "unemployed",
			target: "to_unemployed",
			value: 0,
			color: "#FDC66E",
			startColor: "#FDC66E",
			endColor: "#FDC66E",
		},
		{
			source: "unemployed",
			target: "to_employed",
			value: 0,
			color: "#FDC66E",
			startColor: "#FDC66E",
			endColor: "#0075b0",
		},
		{
			source: "unemployed",
			target: "to_outside",
			value: 0,
			color: "#FDC66E",
			startColor: "#FDC66E",
			endColor: "#FF6875",
		},
		{
			source: "outside",
			target: "to_outside",
			value: 0,
			color: "#FF6875",
			startColor: "#FF6875",
			endColor: "#FF6875",
		},
		{
			source: "outside",
			target: "to_employed",
			value: 0,
			color: "#FF6875",
			startColor: "#FF6875",
			endColor: "#0075b0",
		},
		{
			source: "outside",
			target: "to_unemployed",
			value: 0,
			color: "#FF6875",
			startColor: "#FF6875",
			endColor: "#FDC66E",
		},
	],
};

import React, { useState, useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../LanguageContext";
import { LiveMessage } from "react-aria-live";

import { strings } from "../../strings/budget";
import Select from "react-select";

const SectorSelector = ({ selectedSector, setSelectedSector, sectorNames }) => {
	const lang = useContext(LanguageContext);
	const [message, setMessage] = useState("");

	const sectorOptions = sectorNames.map((sector) => {
		return { value: sector, label: sector };
	});

	sectorOptions.reverse();
	sectorOptions.unshift({ value: "all", label: strings[lang]["all"] });

	const handleSectorChange = (event) => {
		setSelectedSector(event.value);
		setMessage(
			`${strings[lang]["change_message"]} (${strings[lang][event.value]})`
		);
	};

	return (
		<>
			<LiveMessage message={message} aria-live="polite" />
			<Label htmlFor="sector-select">
				{strings[lang]["sector_select"]}:
			</Label>
			<Select
				options={sectorOptions}
				defaultValue={sectorOptions.find(
					(obj) => obj.value === selectedSector
				)}
				inputId="sector-select"
				placeholder={`${strings[lang]["sector_select"]}...`}
				noOptionsMessage={() => strings[lang]["no_hits"]}
				onChange={(event) => handleSectorChange(event)}
				styles={selectStyles}
				isSearchable={false}
			/>
		</>
	);
};

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: "100%",
		maxWidth: "380px",
	}),
};

const Label = styled.label`
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0.5rem;
	margin-top: 1rem;

	@media (min-width: 600px) {
		margin-left: 2rem;
		margin-right: 0.75rem;
		margin-bottom: 0;
		margin-top: 0;
	}
`;

export default SectorSelector;

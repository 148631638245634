export const colors = [
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
	"#1f77b4",
];

export const budgetColors = {
	budget: {
		text: "#ffffff",
		background: "#002C5F",
	},
	additional_budget_1: {
		text: "#ffffff",
		background: "#0075B0",
	},
	additional_budget_2: {
		text: "#ffffff",
		background: "#196B6F",
	},
	additional_budget_3: {
		text: "#000000",
		background: "#F9C867",
	},
	additional_budget_4: {
		text: "#ffffff",
		background: "#C50084",
	},
	income: {
		text: "#ffffff",
		background: "#002C5F",
	},
	income_deductions: {
		text: "#ffffff",
		background: "#002C5F",
	},
	loans: {
		text: "#000000",
		background: "#F9C867",
	},
	loans_additions: {
		text: "#000000",
		background: "#FFE3AD",
	},
	expenditure: {
		text: "#ffffff",
		background: "#C50084",
	},
	expenditure_additions: {
		text: "#ffffff",
		background: "#A70070",
	},
};

export const scenarios = {
	fi: {
		ref_sce: {
			name: "AWG-työryhmän referenssiskenaario",
			short: "AWG referenssiskenaario",
		},
		risk_sce: {
			name: "AWG-työryhmän riskiskenaario",
			short: "AWG riskiskenaario",
		},
		high_lif_exp_sce: {
			name: "Korkean eliniän odotteen skenaario (+2 vuotta)",
			short: "Korkean eliniän odote (+2v)",
		},
		lower_fertility: {
			name: "Matalamman syntyvyyden skenaario (-20%)",
			short: "Matala syntyvyys (-20%)",
		},
		high_tfp: {
			name: "Korkeamman kokonaistuottavuuden skenaario (+0.4 %-yks.)",
			short: "Korkea kokonaistuottavuus (+0.4 %-yks.)",
		},
		high_employ: {
			name: "Korkeamman työllisyysasteen skenaario (+2 %-yks.)",
			short: "Korkeampi työllisyysaste (+2 %-yks.)",
		},
		low_employ: {
			name: "Matalamman työllisyysasteen skenaario (-2 %-yks.)",
			short: "Matalampi työllisyysaste (-2 %-yks.)",
		},
		old_workers_high: {
			name: "Ikääntyneiden korkeampi työllisyysaste (+10 %-yks.)",
			short: "Ikääntyneiden korkeampi työllisyysaste (+10 %-yks.)",
		},
		high_migr: {
			name: "Korkeamman maahanmuuton skenaario (+33%)",
			short: "Korkeampi maahanmuutto (+33%)",
		},
		low_migr: {
			name: "Matalamman maahanmuuton skenaario (-33%)",
			short: "Matalampi maahanmuutto (-33%)",
		},
		healthy_ageing: {
			name:
				"Terveen ikääntymisen skenaario (eliniän pidennys kokonaan terveitä elinvuosia)",
			short: "Terve ikääntyminen",
		},
	},
	en: {
		ref_sce: {
			name: "AWG-työryhmän referenssiskenaario",
			short: "AWG referenssiskenaario",
		},
		risk_sce: {
			name: "AWG-työryhmän riskiskenaario",
			short: "AWG riskiskenaario",
		},
		high_lif_exp_sce: {
			name: "Korkean eliniän odotteen skenaario (+2 vuotta)",
			short: "Korkean eliniän odote (+2v)",
		},
		lower_fertility: {
			name: "Matalamman syntyvyyden skenaario (-20%)",
			short: "Matala syntyvyys (-20%)",
		},
		high_tfp: {
			name: "Korkeamman kokonaistuottavuuden skenaario (+0.4 %-yks.)",
			short: "Korkea kokonaistuottavuus (+0.4 %-yks.)",
		},
		high_employ: {
			name: "Korkeamman työllisyysasteen skenaario (+2 %-yks.)",
			short: "Korkeampi työllisyysaste (+2 %-yks.)",
		},
		low_employ: {
			name: "Matalamman työllisyysasteen skenaario (-2 %-yks.)",
			short: "Matalampi työllisyysaste (-2 %-yks.)",
		},
		old_workers_high: {
			name: "Ikääntyneiden korkeampi työllisyysaste (+10 %-yks.)",
			short: "Ikääntyneiden korkeampi työllisyysaste (+10 %-yks.)",
		},
		high_migr: {
			name: "Korkeamman maahanmuuton skenaario (+33%)",
			short: "Korkeampi maahanmuutto (+33%)",
		},
		low_migr: {
			name: "Matalamman maahanmuuton skenaario (-33%)",
			short: "Matalampi maahanmuutto (-33%)",
		},
		healthy_ageing: {
			name:
				"Terveen ikääntymisen skenaario (eliniän pidennys kokonaan terveitä elinvuosia)",
			short: "Terve ikääntyminen",
		},
	},
	sv: {
		ref_sce: {
			name: "AWG-työryhmän referenssiskenaario",
			short: "AWG referenssiskenaario",
		},
		risk_sce: {
			name: "AWG-työryhmän riskiskenaario",
			short: "AWG riskiskenaario",
		},
		high_lif_exp_sce: {
			name: "Korkean eliniän odotteen skenaario (+2 vuotta)",
			short: "Korkean eliniän odote (+2v)",
		},
		lower_fertility: {
			name: "Matalamman syntyvyyden skenaario (-20%)",
			short: "Matala syntyvyys (-20%)",
		},
		high_tfp: {
			name: "Korkeamman kokonaistuottavuuden skenaario (+0.4 %-yks.)",
			short: "Korkea kokonaistuottavuus (+0.4 %-yks.)",
		},
		high_employ: {
			name: "Korkeamman työllisyysasteen skenaario (+2 %-yks.)",
			short: "Korkeampi työllisyysaste (+2 %-yks.)",
		},
		low_employ: {
			name: "Matalamman työllisyysasteen skenaario (-2 %-yks.)",
			short: "Matalampi työllisyysaste (-2 %-yks.)",
		},
		old_workers_high: {
			name: "Ikääntyneiden korkeampi työllisyysaste (+10 %-yks.)",
			short: "Ikääntyneiden korkeampi työllisyysaste (+10 %-yks.)",
		},
		high_migr: {
			name: "Korkeamman maahanmuuton skenaario (+33%)",
			short: "Korkeampi maahanmuutto (+33%)",
		},
		low_migr: {
			name: "Matalamman maahanmuuton skenaario (-33%)",
			short: "Matalampi maahanmuutto (-33%)",
		},
		healthy_ageing: {
			name:
				"Terveen ikääntymisen skenaario (eliniän pidennys kokonaan terveitä elinvuosia)",
			short: "Terve ikääntyminen",
		},
	},
};

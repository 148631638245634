import React, { useState, useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../LanguageContext";
import { LiveMessage } from "react-aria-live";

import { strings } from "../../strings/employment";
import Select from "react-select";

const GenderSelector = ({ setSelectedGender }) => {
	const lang = useContext(LanguageContext);
	const [message, setMessage] = useState("");

	const genderOptions = [
		{ value: "both", label: strings[lang]["both"] },
		{ value: "male", label: strings[lang]["male"] },
		{ value: "female", label: strings[lang]["female"] },
	];

	const handleGenreChange = (event) => {
		setSelectedGender(event.value);
		setMessage(
			`${strings[lang]["change_message"]} (${strings[lang][event.value]})`
		);
	};

	return (
		<>
			<LiveMessage message={message} aria-live="polite" />
			<Label htmlFor="genre-select">Sukupuoli:</Label>
			<Select
				options={genderOptions}
				defaultValue={genderOptions[0]}
				inputId="genre-select"
				placeholder="Valitse sukupuoli..."
				noOptionsMessage={() => "Ei osumia"}
				onChange={(event) => handleGenreChange(event)}
				styles={selectStyles}
				isSearchable={false}
			/>
		</>
	);
};

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: "100%",
		maxWidth: "250px",
	}),
};

const Label = styled.label`
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0.5rem;
	margin-top: 1rem;

	@media (min-width: 600px) {
		margin-left: 2rem;
		margin-right: 0.75rem;
		margin-bottom: 0;
		margin-top: 0;
	}
`;

export default GenderSelector;
